import React from 'react';
import { PROVIDERS } from './provider';
import { ftpImages } from './ftpImages';

const { HOME_PL, CYBERFOLKS_PL, LH_PL, NAZWA_PL, SMARTHOST_PL, UTI_PL, ZENBOX_PL, AZ_PL, OVHCLOUD_PL, KEI_PL, SEOHOST_PL, DHOSTING_PL } = PROVIDERS;

export const getftpInstructionsByProvider = (lang: string) => {
  console.log(lang);
  return {
    [HOME_PL]: [
      {
        title: <>Zaloguj się do Panelu klienta home.pl</>,
        description: (
          <>
            Przejdź na stronę{' '}
            <a href="https://panel.home.pl" target="_blank" rel="noopener noreferrer">
              panel.home.pl
            </a>{' '}
            i wprowadź dane logowania.
          </>
        ),
        image: ftpImages[HOME_PL]?.[lang]?.[0],
      },
      {
        title: <>Przejdź do ustawień serwera, klikając w Usługi WWW po lewej stronie ekranu.</>,
        description: <></>,
        image: ftpImages[HOME_PL]?.[lang]?.[1],
      },
      {
        title: <>Wybierz odpowiedni serwer.</>,
        description: <>Na liście dostępnych usług znajdź serwer, na którym chcesz utworzyć konto FTP, i kliknij jego nazwę.</>,
        image: ftpImages[HOME_PL]?.[lang]?.[2],
      },
      {
        title: <>Dodaj nowe konto FTP.</>,
        description: (
          <>
            W sekcji „Konta FTP” kliknij przycisk <span>„Utwórz”</span>.
            <br />
            <br />
            📌{' '}
            <strong>
              W tej sekcji, pod tytułem `Host FTP`, znajdziesz nazwę serwera – będzie Ci potrzebna podczas dodawania danych do konta FTP w Corgim.
            </strong>
          </>
        ),
        image: ftpImages[HOME_PL]?.[lang]?.[3],
      },
      {
        title: <>Wprowadź dane nowego konta w formularzu „Nowe konto FTP”</>,
        description: (
          <>
            <ul>
              <li>
                <strong>Login</strong>: unikalna nazwa użytkownika dla konta FTP.
              </li>
              <li>
                <strong>Domena</strong>: jeśli pozostawisz pole „Domena” z wybraną domeną, nowe konto automatycznie przypisze konto FTP do katalogu
                głównego tej strony.
              </li>
              <li>
                <strong>Hasło</strong>: silne hasło, najlepiej zawierające kombinację wielkich i małych liter, cyfr oraz symboli.
              </li>
            </ul>
          </>
        ),
        image: ftpImages[HOME_PL]?.[lang]?.[4],
      },
      {
        title: <>Zdefiniuj dostęp do katalogu.</>,
        description: (
          <>
            <strong>Katalog do edycji zawartości serwera WWW</strong>: określ, do którego katalogu użytkownik FTP będzie miał dostęp. Jeśli chcesz
            ograniczyć użytkownika tylko do wybranej domeny lub katalogu – wybierz konkretną ścieżkę z listy. Możesz też wpisać ścieżkę ręcznie.
            <br />
            <br />
            📁 Jeśli w ustawieniach konta FTP znajduje się pole <em>„Katalog”</em>, określ ścieżkę katalogu oraz jego zawartość.
          </>
        ),
        image: ftpImages[HOME_PL]?.[lang]?.[5],
      },
      {
        title: <>Zapisz zmiany</>,
        description: (
          <>
            Po wprowadzeniu wszystkich danych, kliknij przycisk <span>„Zapisz”</span>, aby utworzyć konto.
          </>
        ),
        image: ftpImages[HOME_PL]?.[lang]?.[6],
      },
      {
        title: <>Konto zostało utworzone.</>,
        description: (
          <>
            Na liście użytkowników kont FTP pojawi się nowo utworzone konto.
            <br />
            <br />
            <span style={{ color: 'blue' }}>Pamiętaj, aby zapisać dane do logowania w bezpiecznym miejscu.</span>
          </>
        ),
        image: ftpImages[HOME_PL]?.[lang]?.[7],
      },
    ],
    [CYBERFOLKS_PL]: [
      {
        title: <>Zaloguj się do Panelu Klienta</>,
        description: (
          <>
            Przejdź na stronę{' '}
            <a href="https://cyberfolks.pl" target="_blank" rel="noopener noreferrer">
              cyberfolks.pl
            </a>{' '}
            i kliknij przycisk „Zaloguj się” w prawym, górnym rogu. Wybierz opcję „Panel klienta” i wprowadź swoje dane logowania.
          </>
        ),
        image: ftpImages[CYBERFOLKS_PL]?.[lang]?.[0],
      },
      {
        title: <>Wybierz usługę hostingową</>,
        description: <>Po zalogowaniu w panelu po lewej stronie rozwiń sekcję „Twoje usługi” i wybierz opcję „Hosting”.</>,
        image: ftpImages[CYBERFOLKS_PL]?.[lang]?.[1],
      },
      {
        title: <>Przejdź do panelu administracyjnego Direct_Admin</>,
        description: (
          <>
            Znajdź usługę hostingową, dla której chcesz utworzyć konto FTP, i kliknij przycisk <span>„Zaloguj do Direct Admin”</span>.
          </>
        ),
        image: ftpImages[CYBERFOLKS_PL]?.[lang]?.[2],
      },
      {
        title: <>Wybierz domenę, w której chcesz utworzyć konto FTP</>,
        description: (
          <>
            Po zalogowaniu do panelu Direct Admin znajdź domenę, w której chcesz utworzyć konto FTP, i kliknij w nią.
            <br />
            <br />
            <span style={{ color: 'blue' }}>
              📌 W panelu po lewej stronie znajdziesz nazwę serwera – będzie ci potrzebna podczas dodawania danych do konta FTP w Corgim.
            </span>
          </>
        ),
        image: ftpImages[CYBERFOLKS_PL]?.[lang]?.[3],
      },
      {
        title: (
          <>
            Znajdź sekcję „Serwer WWW i domeny, a następnie kliknij opcję „<span>Konta FTP</span>”.
          </>
        ),
        description: <></>,
        image: ftpImages[CYBERFOLKS_PL]?.[lang]?.[4],
      },
      {
        title: (
          <>
            W panelu zarządzania FTP kliknij przycisk „<span>Utwórz konto FTP</span>”.
          </>
        ),
        description: <></>,
        image: ftpImages[CYBERFOLKS_PL]?.[lang]?.[5],
      },
      {
        title: <>Uzupełnij dane w formularzu..</>,
        description: (
          <>
            Wpisz <span>nazwę użytkownika, nowe hasło,</span> oraz <span>wybierz zakres katalogów,</span>do których utworzone konto będzie miało
            dostęp. Najczęściej wybierane opcje to katalog plików wybranej domeny lub ostatni wariant slash, czyli ukośnik. Zezwalają na dostęp do
            wszystkich plików w hostingu. W tym polu możesz też podać ręcznie ścieżkę do konkretnego katalogu.
            <br />
            Aby zakończyć kliknij przycisk „<span>Utwórz</span>” i gotowe
          </>
        ),
        image: ftpImages[CYBERFOLKS_PL]?.[lang]?.[6],
      },
    ],
    [LH_PL]: [
      {
        title: (
          <>
            Zaloguj się do panelu klienta pod adresem{' '}
            <a href="https://www.lh.pl/panel/login" target="_blank" rel="noopener noreferrer">
              www.lh.pl/panel/login
            </a>
          </>
        ),
        description: <>Podaj swój login i hasło.</>,
        image: ftpImages[LH_PL]?.[lang]?.[0],
      },
      {
        title: <>Wybierz odpowiedni serwer</>,
        description: (
          <>
            W panelu po lewej rozwiń zakładkę „<span>Serwery</span>” i wybierz serwer, na którym chcesz dodać nowe konto FTP.
          </>
        ),
        image: ftpImages[LH_PL]?.[lang]?.[1],
      },
      {
        title: <>Otwórz zakładkę „konta FTP” i utwórz nowe</>,
        description: (
          <>
            Po kliknięciu we właściwy serwer wybierz zakładkę „Konta FTP”. Następnie kliknij przycisk „<span>Utwórz</span>”.
            <br />
            <br />
            <span style={{ color: 'blue' }}>
              📌 W panelu „Dodatkowe informacje” po prawej stronie znajdziesz nazwę serwera – będzie Ci potrzebna podczas dodawania danych do konta
              FTP w Corgim.
            </span>
          </>
        ),
        image: ftpImages[LH_PL]?.[lang]?.[2],
      },
      {
        title: <>Uzupełnij dane w formularzu i zapisz.</>,
        description: (
          <>
            Podaj nazwę, hasło oraz wybierz katalog na serwerze, do którego konto będzie miało dostęp. Kliknij przycisk „<span>Utwórz</span>” i
            gotowe!
            <br />
            <br />
            Pamiętaj, aby dane do konta FTP zapisać w bezpiecznym miejscu.
          </>
        ),
        image: ftpImages[LH_PL]?.[lang]?.[3],
      },
    ],
    [NAZWA_PL]: [
      {
        title: (
          <>
            Zaloguj się do Panelu Cloud Hosting pod adresem{' '}
            <a href="https://admin.nazwa.pl" target="_blank" rel="noopener noreferrer">
              admin.nazwa.pl
            </a>
          </>
        ),
        description: <>Podaj nazwę użytkownika i hasło.</>,
        image: ftpImages[NAZWA_PL]?.[lang]?.[0],
      },
      {
        title: <>Otwórz Wykaz kont FTP</>,
        description: <>Najedź na zakładkę „WWW i FTP” w górnym menu i wybierz opcję „Wykaz kont FTP”.</>,
        image: ftpImages[NAZWA_PL]?.[lang]?.[1],
      },
      {
        title: <>Dodaj konto FTP</>,
        description: <>Po otworzeniu Wykazu kont FTP kliknij w przycisk „Dodaj konto FTP”.</>,
        image: ftpImages[NAZWA_PL]?.[lang]?.[2],
      },
      {
        title: <>Uzupełnij dane w formularzu</>,
        description: (
          <>
            <ul>
              <li>
                <strong>Nazwa konta</strong> – wpisz nazwę konta FTP, która będzie jednocześnie loginem.
              </li>
              <li>
                <strong>Katalog</strong> – wybierz katalog, do którego konto FTP będzie miało dostęp. Rekomendowane w tym miejscu to „/” (ukośnik) –
                zapewnia pełny dostęp do całego katalogu usług.
              </li>
              <li>
                <strong>Opis</strong> (opcjonalnie) – nadaj krótką nazwę, aby w łatwy sposób zidentyfikować dostęp FTP.
              </li>
              <li>
                <strong>Hasło / Powtórz hasło</strong> – wpisz hasło, które będzie służyć do logowania.
              </li>
            </ul>
            <br />
            Po uzupełnieniu formularza kliknij przycisk <strong>„Zapisz”</strong>, aby konto zostało utworzone.
            <br />
            <br />
            <span style={{ color: 'blue' }}>
              📌 Tutaj znajdziesz również nazwę serwera (na początku pola „Nazwa konta”) – będzie Ci potrzebna podczas dodawania danych do konta FTP w
              Corgim.
            </span>
          </>
        ),
        image: ftpImages[NAZWA_PL]?.[lang]?.[3],
      },
      {
        title: <>Gotowe!</>,
        description: <>Prawidłowe dodanie konta FTP zostanie potwierdzone komunikatem.</>,
        image: ftpImages[NAZWA_PL]?.[lang]?.[4],
      },
    ],
    [SMARTHOST_PL]: [
      {
        title: (
          <>
            Zaloguj się do swojego panelu abonenta pod adresem{' '}
            <a href="https://www.smarthost.pl/panel/login" target="_blank" rel="noopener noreferrer">
              www.smarthost.pl/panel/login
            </a>
          </>
        ),
        description: <>Podaj swój e-mail i hasło.</>,
        image: ftpImages[SMARTHOST_PL]?.[lang]?.[0],
      },
      {
        title: <>Zaloguj się do cPanel</>,
        description: (
          <>
            Znajdź odpowiednią usługę hostingową na liście i kliknij przycisk „<span>Zaloguj się do cPanel</span>”.
            <br />
            <br />
            <span style={{ color: 'blue' }}>
              📌 Pod nazwą usługi hostingowej znajdziesz nazwę serwera – będzie Ci potrzebna podczas dodawania danych do konta FTP w Corgim.
            </span>
          </>
        ),
        image: ftpImages[SMARTHOST_PL]?.[lang]?.[1],
      },
      {
        title: <>Wybierz opcję „Konta FTP” (FTP Accounts)</>,
        description: (
          <>
            Znajdź sekcję „Pliki” (Files) i wybierz opcję <span>„Konta FTP”</span> (FTP Accounts).
          </>
        ),
        image: ftpImages[SMARTHOST_PL]?.[lang]?.[2],
      },
      {
        title: <>Uzupełnij formularz tworzenia konta FTP</>,
        description: (
          <>
            <ol>
              <li>Login</li>
              <li>Hasło</li>
              <li>Powtórz hasło</li>
              <li>W tym polu należy podać ścieżkę dostępową do katalogu, w którym mają się pojawiać pliki źródłowe strony.</li>
            </ol>
            <br />
            Jeśli chcesz umieścić stronę w katalogu głównym (aby była widoczna pod konkretną domeną po instalacji), zazwyczaj ścieżką jest{' '}
            <strong>„/public_html”</strong>.
            <br />
            Jeśli strona ma działać w innym folderze (np. <strong>mojadomena.pl/blog</strong>), wpisz: <strong>„/public_html/blog”</strong>.
            <br />
            <br />
            Po uzupełnieniu kliknij przycisk <strong>„Utwórz konto FTP”</strong> (Create FTP Account) i gotowe!
            <br />
            <br />
            Pamiętaj, aby zapisać dane do logowania w bezpiecznym miejscu.
          </>
        ),
        image: ftpImages[SMARTHOST_PL]?.[lang]?.[3],
      },
    ],
    [UTI_PL]: [
      {
        title: <>Zaloguj się do panelu klienta PLESK</>,
        description: <>Podaj swój login i hasło.</>,
        image: ftpImages[UTI_PL]?.[lang]?.[0],
      },
      {
        title: <>Znajdź właściwą domenę i otwórz „Dostęp FTP”</>,
        description: (
          <>
            W panelu po lewej kliknij „<span>Strony WWW i domeny</span>” i rozwiń listę obok przy domenie, do której chcesz dodać konto FTP.
            <br />
            <br />
            Następnie wybierz zakładkę „<span>Dashboard</span>” i kliknij opcję <span>„Dostęp FTP”</span>.
          </>
        ),
        image: ftpImages[UTI_PL]?.[lang]?.[1],
      },
      {
        title: <>Kliknij przycisk „Dodaj konto FTP”</>,
        description: <></>,
        image: ftpImages[UTI_PL]?.[lang]?.[2],
      },
      {
        title: <>Uzupełnij formularz tworzenia konta FTP</>,
        description: (
          <>
            <ol>
              <li>
                <strong>Nazwa konta FTP</strong>
              </li>
              <li>
                <strong>Katalog domowy</strong> – Jeśli chcesz dać dostęp tylko do plików danej strony – wpisujesz katalog tej strony (np.{' '}
                <strong>/public_html/mojadomena</strong>). Jeśli chcesz mieć dostęp do wszystkich plików na serwerze – ustawiasz katalog główny
                (root).
              </li>
              <li>
                <strong>Hasło</strong>
              </li>
              <li>
                <strong>Potwierdź hasło</strong>
              </li>
            </ol>
            <br />
            Po uzupełnieniu danych, zatwierdź je przyciskiem „<span>OK</span>” i gotowe!
            <br />
            <br />
            Pamiętaj, aby zapisać dane w bezpiecznym miejscu.
          </>
        ),
        image: ftpImages[UTI_PL]?.[lang]?.[3],
      },
      {
        title: (
          <>
            Znajdź <span>nazwę serwera</span>, która będzie potrzebna do uzupełnienia danych do FTP w Corgim
          </>
        ),
        description: (
          <>
            W panelu klienta po lewej stronie wybierz opcję „<span>Strony WWW i domeny</span>”.
            <br />
            <br />
            Kliknij nazwę domeny, lub strony, dla której chcesz utworzyć konto FTP, a następnie otwórz sekcję <span>„Informacje ogólne”</span>.
            <br />
            <br />
            📌 <strong>Adres serwera (host)</strong> – to jest nazwa serwera, która należy podać podczas dodawania danych do FTP w Corgim.
          </>
        ),
        image: ftpImages[UTI_PL]?.[lang]?.[4],
      },
    ],
    [ZENBOX_PL]: [
      {
        title: (
          <>
            Zaloguj się do panelu klienta pod adresem{' '}
            <a href="https://www.panel.zenbox.pl" target="_blank" rel="noopener noreferrer">
              www.panel.zenbox.pl
            </a>
          </>
        ),
        description: <>Podaj swój e-mail i hasło.</>,
        image: ftpImages[ZENBOX_PL]?.[lang]?.[0],
      },
      {
        title: <>Przejdź do zakładki „Konta FTP”</>,
        description: <>W panelu po lewej stronie rozwiń sekcję „Hosting” i wybierz zakładkę „Konta FTP”.</>,
        image: ftpImages[ZENBOX_PL]?.[lang]?.[1],
      },
      {
        title: <>Wybierz domenę, dla której chcesz dodać nowe konto FTP</>,
        description: <></>,
        image: ftpImages[ZENBOX_PL]?.[lang]?.[2],
      },
      {
        title: <>Kliknij przycisk „Utwórz konto FTP”</>,
        description: <></>,
        image: ftpImages[ZENBOX_PL]?.[lang]?.[3],
      },
      {
        title: <>Uzupełnij formularz</>,
        description: (
          <>
            <ol>
              <li>Nazwa użytkownika konta FTP</li>
              <li>Hasło</li>
              <li>Powtórz hasło</li>
              <li>
                Wybierz typ konta FTP:
                <ul>
                  <li>
                    <strong>Dostęp pełny</strong> – jeśli użytkownik ma mieć dostęp do wszystkich plików domeny, wybierz opcję domyślną.
                  </li>
                  <li>
                    <strong>Dostęp do wybranego katalogu</strong> – jeśli chcesz ograniczyć użytkownika tylko do konkretnej lokalizacji, podaj ścieżkę
                    dostępu (np. <strong>/public_html/domena</strong>).
                  </li>
                </ul>
              </li>
            </ol>
            <br />
            Po uzupełnieniu kliknij przycisk <strong>„Utwórz”</strong>. Twoje konto FTP zostanie utworzone, a system automatycznie przypisze wymagane
            serwery.
            <br />
            <br />
            Pamiętaj, aby dane do konta FTP przechowywać w bezpiecznym miejscu.
          </>
        ),
        image: ftpImages[ZENBOX_PL]?.[lang]?.[4],
      },
      {
        title: <>Znajdź nazwę serwera w sekcji „Moje konto” na zakładce „Start”</>,
        description: <>Nazwa serwera będzie Ci potrzebna podczas dodawania danych do konta FTP w Corgim.</>,
        image: ftpImages[ZENBOX_PL]?.[lang]?.[5],
      },
    ],
    [AZ_PL]: [
      {
        title: (
          <>
            Zaloguj się do panelu klienta pod adresem{' '}
            <a href="https://www.az.pl/login/" target="_blank" rel="noopener noreferrer">
              www.az.pl/login/
            </a>
          </>
        ),
        description: <>Podaj swój login i hasło.</>,
        image: ftpImages[AZ_PL]?.[lang]?.[0],
      },
      {
        title: <>Przejdź do sekcji „Usługi WWW” w panelu po lewej stronie i wybierz odpowiedni serwer</>,
        description: <>Na liście dostępnych usług znajdź serwer, na którym chcesz utworzyć konto FTP, i kliknij jego nazwę.</>,
        image: ftpImages[AZ_PL]?.[lang]?.[1],
      },
      {
        title: <>W sekcji „Konta FTP” wybierz „Utwórz”</>,
        description: (
          <>
            📌 W tej sekcji znajdziesz również nazwę serwera, pod tytułem <span>„Host FTP”</span> – będzie Ci potrzebna podczas dodawania danych do
            konta FTP w Corgim.
          </>
        ),
        image: ftpImages[AZ_PL]?.[lang]?.[2],
      },
      {
        title: <>Uzupełnij formularz</>,
        description: (
          <>
            <ul>
              <li>
                <strong>Login</strong>: unikalna nazwa użytkownika dla konta FTP.
              </li>
              <li>
                <strong>Domena</strong>: jeśli pozostawisz to pole „Domena”, twoje konto FTP zostanie przypisane do katalogu głównego tej domeny.
              </li>
              <li>
                <strong>Hasło</strong>: wpisz hasło, które będzie używane do logowania do konta FTP.
              </li>
              <li>
                <strong>Uprawnienia FTP</strong>: zdecyduj, do jakiego katalogu użytkownik będzie miał dostęp.
                <ul>
                  <li>
                    <strong>Cała zawartość serwera WWW</strong>: daje użytkownikowi pełen dostęp do wszystkich plików na serwerze.
                  </li>
                  <li>
                    <strong>Wybrany katalog</strong>: pozwala ograniczyć użytkownika tylko do konkretnego katalogu, np. <span>/public_html</span> dla
                    głównej strony internetowej.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Kopia bezpieczeństwa</strong>: włącz, jeśli chcesz, aby konto FTP umożliwiało dostęp do zapisanych kopii zapasowych.
              </li>
            </ul>
            <br />
            Po uzupełnieniu formularza kliknij przycisk <strong>„Utwórz”</strong>, aby utworzyć konto.
            <br />
            <br />
            Jeśli chcesz anulować proces, wybierz opcję <strong>„Anuluj”</strong>, aby usunąć konto.
          </>
        ),
        image: ftpImages[AZ_PL]?.[lang]?.[3],
      },
    ],
    [OVHCLOUD_PL]: [
      {
        title: <>Zaloguj się do panelu klienta OVHcloud</>,
        description: <>Podaj swój login i hasło.</>,
        image: ftpImages[OVHCLOUD_PL]?.[lang]?.[0],
      },
      {
        title: <>Przejdź do sekcji „Web Cloud” w górnym menu</>,
        description: (
          <>
            W panelu po lewej stronie wybierz zakładkę <span>„Web Cloud”</span> i przejdź do zakładki „Hosting”.
          </>
        ),
        image: ftpImages[OVHCLOUD_PL]?.[lang]?.[1],
      },
      {
        title: <>Na liście, która się wyświetli, kliknij zakładkę „FTP-SSH”</>,
        description: (
          <>
            W nowym widoku znajdziesz listę użytkowników FTP. Jeśli nie masz jeszcze utworzonego konta, kliknij „Dodaj użytkownika”.
            <br />
            <br />
            📌 <strong>W tej sekcji znajdziesz również nazwę serwera</strong> – będzie Ci potrzebna podczas dodawania danych do konta FTP w Corgim.
          </>
        ),
        image: ftpImages[OVHCLOUD_PL]?.[lang]?.[2],
      },
      {
        title: <>Uzupełnij parametry nowego użytkownika</>,
        description: (
          <>
            <ul>
              <li>
                <strong>Login</strong>: wybierz nazwę użytkownika dla konta FTP.
              </li>
              <li>
                <strong>Katalog główny</strong>: wybierz katalog bazowy lub podaj ścieżkę do konkretnej lokalizacji.
              </li>
              <li>
                <strong>Uprawnienia</strong>: zdecyduj, czy użytkownik będzie miał dostęp tylko do FTP, czy także do SSH.
              </li>
              <li>
                <strong>Data wygaśnięcia</strong>: ustaw, jeśli chcesz, aby konto było aktywne tylko przez określony czas.
              </li>
            </ul>
            <br />
            Po uzupełnieniu formularza kliknij przycisk <strong>„Zapisz”</strong>.
          </>
        ),
        image: ftpImages[OVHCLOUD_PL]?.[lang]?.[3],
      },
      {
        title: <>Utwórz i potwierdź hasło nowego użytkownika</>,
        description: (
          <>
            Podaj nowe hasło, powtórz je, a następnie kliknij <strong>„Zmień”</strong> i czekaj na aktywację dostępu.
          </>
        ),
        image: ftpImages[OVHCLOUD_PL]?.[lang]?.[4],
      },
      {
        title: <>Ostatni etap: podsumowanie i aktywacja</>,
        description: (
          <>
            Po zakończeniu procesu system wygeneruje nowe dane użytkownika FTP. Jeśli termin aktywacji nie jest podany, kliknij{' '}
            <strong>„Odśwież”</strong> i sprawdź, czy konto zostało utworzone.
          </>
        ),
        image: ftpImages[OVHCLOUD_PL]?.[lang]?.[5],
      },
    ],
    [KEI_PL]: [
      {
        title: (
          <>
            Zaloguj się do panelu klienta WebAs pod adresem{' '}
            <a href="https://www.panel.kei.pl/webas" target="_blank" rel="noopener noreferrer">
              www.panel.kei.pl/webas
            </a>
          </>
        ),
        description: <>Podaj swój login, hasło i domenę.</>,
        image: ftpImages[KEI_PL]?.[lang]?.[0],
      },
      {
        title: <>Przejdź do zakładki Usługi &gt; FTP &gt; KONTA FTP, a następnie kliknij „Nowe konto”</>,
        description: (
          <>
            Jeśli w danej usłudze są już dodane konta FTP, to powinien wyświetlić się niebieski przycisk <span>„NOWE KONTO”</span>.
          </>
        ),
        image: ftpImages[KEI_PL]?.[lang]?.[1],
      },
      {
        title: <>Uzupełnij formularz</>,
        description: (
          <>
            <ul>
              <li>
                <strong>Nazwa konta</strong>: unikalna nazwa użytkownika dla konta FTP.
              </li>
              <li>
                <strong>Opis konta</strong>: nadaj krótki opis, aby w łatwy sposób zidentyfikować stronę lub usługę, do której konto FTP będzie miało
                dostęp.
              </li>
              <li>
                <strong>Podkatalog dla konta</strong>:
                <ul>
                  <li>
                    Wpisz <span>/public_html</span>, jeśli chcesz dać dostęp do całej strony (głównego katalogu strony).
                  </li>
                  <li>
                    Jeśli chcesz dać dostęp tylko do podkatalogu, np. do bloga – wpisz np. <span>/moje_pliki</span>, dzięki czemu użytkownik będzie
                    ograniczony tylko do określonego katalogu i nie będzie miał dostępu do innych plików strony.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Hasło</strong>
              </li>
              <li>
                <strong>Powtórz hasło</strong>
              </li>
              <li>
                <strong>Dostęp do danych</strong>: możesz dowolnie ustalić poziom uprawnień użytkownika – czy będzie miał możliwość pobierania,
                wysyłania, bądź edycji plików.
              </li>
            </ul>
            <br />
            Po uzupełnieniu ustawień kliknij przycisk <strong>„Zapisz formularz”</strong> i gotowe!
          </>
        ),
        image: ftpImages[KEI_PL]?.[lang]?.[2],
      },
      {
        title: <>Znajdź nazwę serwera w Panelu klienta Kei.pl</>,
        description: (
          <>
            Masz już login i hasło do konta FTP. Teraz wystarczy, że odczytasz nazwę serwera – znajdziesz ją w zakładce <span>„Start”</span>, pod
            nazwą usługi.
            <br />
            <br />
            📌 <strong>Nazwa serwera będzie Ci potrzebna podczas dodawania danych do konta FTP w Corgim.</strong>
          </>
        ),
        image: ftpImages[KEI_PL]?.[lang]?.[3],
      },
    ],
    [SEOHOST_PL]: [
      {
        title: (
          <>
            Zaloguj się do panelu klienta seohost pod adresem{' '}
            <a href="https://www.panel.seohost.pl/login" target="_blank" rel="noopener noreferrer">
              www.panel.seohost.pl/login
            </a>
          </>
        ),
        description: (
          <>
            Po zalogowaniu w panelu po lewej kliknij w zakładkę „Serwery”. Znajdź na liście serwer, który Cię interesuje, a następnie kliknij opcję
            „Direct Admin”.
          </>
        ),
        image: ftpImages[SEOHOST_PL]?.[lang]?.[0],
      },
      {
        title: <>Zaloguj się do panelu Direct Admin</>,
        description: <>Wpisz login i hasło otrzymane w mailu po uruchomieniu serwera.</>,
        image: ftpImages[SEOHOST_PL]?.[lang]?.[1],
      },
      {
        title: <>Przejdź do zakładki „Zarządzanie FTP”</>,
        description: (
          <>
            Po zalogowaniu do panelu Direct Admin, w panelu po lewej rozwiń zakładkę „Zarządzaj” i wybierz opcję <span>„Zarządzanie FTP”</span>.
          </>
        ),
        image: ftpImages[SEOHOST_PL]?.[lang]?.[2],
      },
      {
        title: <>Kliknij przycisk „Utwórz konto FTP”</>,
        description: (
          <>
            📌 <strong>W tej zakładce znajdziesz również nazwę serwera</strong> – będzie Ci potrzebna podczas dodawania danych do konta FTP w Corgim.
          </>
        ),
        image: ftpImages[SEOHOST_PL]?.[lang]?.[3],
      },
      {
        title: <>Uzupełnij dane w formularzu.</>,
        description: (
          <>
            <ul>
              <li>
                <strong>Użytkownik FTP</strong>: unikalna nazwa użytkownika dla konta FTP.
              </li>
              <li>
                <strong>Hasło</strong>: ustaw hasło dostępu.
              </li>
              <li>
                <strong>Poziom dostępu do konta</strong>: wybierz odpowiednią opcję:
                <ul>
                  <li>
                    <strong>Domena</strong> – dostęp do katalogu głównego domeny, np. <span>/domains/nazwadomeny.pl/public_ftp</span>.
                  </li>
                  <li>
                    <strong>FTP</strong> – dostęp do katalogu <span>/public_ftp</span>.
                  </li>
                  <li>
                    <strong>Użytkownik</strong> – dostęp tylko do katalogu z nazwą użytkownika, np.{' '}
                    <span>/domains/nazwadomeny.pl/public_html/ftp_konto</span>.
                  </li>
                  <li>
                    <strong>Niestandardowe</strong> – pozwala samodzielnie określić ścieżkę dostępu do plików.
                  </li>
                </ul>
              </li>
            </ul>
            <br />
            Po uzupełnieniu danych kliknij <strong>„Utwórz”</strong> i gotowe!
          </>
        ),
        image: ftpImages[SEOHOST_PL]?.[lang]?.[4],
      },
    ],
    [DHOSTING_PL]: [
      {
        title: (
          <>
            Zaloguj się do panelu klienta pod adresem{' '}
            <a href="https://www.dpanel.pl" target="_blank" rel="noopener noreferrer">
              www.dpanel.pl
            </a>
          </>
        ),
        description: <>Podaj swój login i hasło.</>,
        image: ftpImages[DHOSTING_PL]?.[lang]?.[0],
      },
      {
        title: (
          <>
            Przejdź do zakładki „<span>Konta FTP</span>”
          </>
        ),
        description: <>W panelu po lewej kliknij zakładkę „Konta FTP”.</>,
        image: ftpImages[DHOSTING_PL]?.[lang]?.[1],
      },
      {
        title: (
          <>
            Kliknij przycisk „<span>Dodaj</span>”
          </>
        ),
        description: (
          <>
            📌 <strong>W tej zakładce znajdziesz również nazwę serwera</strong> w panelu „Potrzebne informacje” po prawej stronie – będzie Ci
            potrzebna podczas dodawania danych do konta FTP w Corgim.
          </>
        ),
        image: ftpImages[DHOSTING_PL]?.[lang]?.[2],
      },
      {
        title: <>Uzupełnij dane w formularzu.</>,
        description: (
          <>
            <ul>
              <li>
                <span>Login FTP</span>: unikalna nazwa użytkownika dla konta FTP.
              </li>
              <li>
                <span>Hasło</span>: ustaw hasło dostępu.
              </li>
              <li>
                <span>Katalog</span>:
                <ul>
                  <li>
                    Jeśli chcesz dać dostęp do całej strony (głównego katalogu strony), wpisz <span>/public_html</span>.
                  </li>
                  <li>
                    Jeżeli konto FTP ma mieć dostęp tylko do konkretnego katalogu, wpisz np. <span>/public_html/blog</span>.
                  </li>
                  <li>
                    Jeśli chcesz ograniczyć dostęp do innego katalogu, wpisz np. <span>/moje_pliki</span>.
                  </li>
                </ul>
              </li>
              <li>
                <span>Opis</span>: opcjonalnie wpisz opis, aby łatwo zidentyfikować konto FTP.
              </li>
            </ul>
            <br />
            Po uzupełnieniu danych, kliknij przycisk <span>„Dodaj konto FTP”</span> i gotowe!
          </>
        ),
        image: ftpImages[DHOSTING_PL]?.[lang]?.[3],
      },
    ],
  };
};
