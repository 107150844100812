import React, { useEffect, useState } from 'react';
import { FtpInstructionsMain, FtpSingleInstruction } from './components';
import { useLocation } from 'react-router-dom';

const FtpInstructions = () => {
  const [provider, setProvider] = useState('');
  const location = useLocation();
  useEffect(() => {
    const currentProvider = new URLSearchParams(location.search).get('provider');

    if (currentProvider && currentProvider !== provider) {
      setProvider(currentProvider);
    } else if (!currentProvider && provider !== '') {
      setProvider('');
    }
  }, [location]);

  return <>{provider ? <FtpSingleInstruction setProvider={setProvider} provider={provider} /> : <FtpInstructionsMain />}</>;
};

export default FtpInstructions;
