import { Connector } from 'Modules/AddWebsiteModule';
import { AddWebsite } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: AddWebsite.GET_PUBLIC_KEY_INIT,
});

const success = (data) => ({
  type: AddWebsite.GET_PUBLIC_KEY_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: AddWebsite.GET_PUBLIC_KEY_SUCCESS,
});

const getPublicKey = (companyUuid) => async (dispatch) => {
  dispatch(init());
  try {
    const { data } = await Connector.getPublicKey(companyUuid);
    dispatch(success(data));
  } catch (err) {
    console.log(err);
    dispatch(failure());
  }
};

export default getPublicKey;
