import React, { useState } from 'react';
import { MainType } from './Container';
import TomekWebp from 'Assets/Image/tomasz_organek.png';
import HowItWorksHeroImg from 'Assets/Image/how_it_works_hero.png';
import Button from 'Shared/components/button/Button';
// import Background from '../../Assets/Image/background_hp.svg';
import { FormattedMessage } from 'react-intl';
import { SVG_TYPE } from 'Shared/enums';
import { SVG } from 'Shared/components';
import MetaTags from 'react-meta-tags';
import { InlineWidget } from 'react-calendly';
import FadeInOnScroll from 'Components/FadeInOnScroll/FadeInOnScroll';
import { isMobile } from 'Shared/utils';
import SlideOnScroll from 'Components/SlideOnScroll';
import { DetailsModal } from 'Shared/modals';

const HowItWorks = ({ intl: { formatMessage }, translation }: MainType) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="home-page how-it-works">
      <DetailsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <MetaTags>
        <title>{formatMessage({ id: 'global.metatag.how_it_works_title' })}</title>
        <meta name="description" content={formatMessage({ id: 'global.metatag.how_it_works_description' })} />
      </MetaTags>
      <section className="section__wrapper section--gray section--gray-small-margin">
        <div className="section--background section--background--top250">
          {/* <img src={Background} alt="background image" /> */}
          <SVG type={SVG_TYPE.HERO_SECTION_BG} />
        </div>
        <div className="section banner mainHeading__content heroSection homepage__section-content">
          <FadeInOnScroll className="section__r">
            <h1 className="section--header mainHeading">
              <p className="paragraph">
                {formatMessage({ id: 'global.corgi_is_more_than_plugin' })}{' '}
                <span className="blue">{formatMessage({ id: 'global.corgi_is_more_than_plugin1' })}</span>
                {formatMessage({ id: 'global.corgi_is_more_than_plugin2' })}
              </p>
            </h1>
            <p className="section--under-header">
              {formatMessage({ id: 'global.corgi_is_more_than_plugin_description1' })}
              <b>{formatMessage({ id: 'global.corgi_is_more_than_plugin_description2' })}</b>.
            </p>
          </FadeInOnScroll>
          <FadeInOnScroll delay={isMobile ? 300 : 900} className="how-it-works__main-image-wrapper">
            <img className="how-it-works__main-logo" src={HowItWorksHeroImg} alt="Logo pies woof" />
          </FadeInOnScroll>
        </div>
      </section>

      <section className="section__wrapper section--gray">
        <div className="section homepage__section-content">
          <div className="how-it-works__features-list">
            <FadeInOnScroll delay={isMobile ? 300 : 300} className="how-it-works__feature">
              <span className="how-it-works__feature-index">1</span>
              <h2 className="how-it-works__feature-heading">
                {formatMessage({ id: 'global.how_it_works_list.corgi_works_offline.title1' })}{' '}
                <span className="blue">{formatMessage({ id: 'global.how_it_works_list.corgi_works_offline.title2' })}</span>
              </h2>
              <p className="how-it-works__feature-description">
                {formatMessage({ id: 'global.how_it_works_list.corgi_works_offline.description1' })}{' '}
                <b>{formatMessage({ id: 'global.how_it_works_list.corgi_works_offline.description2' })}</b>
              </p>
            </FadeInOnScroll>
            <FadeInOnScroll delay={isMobile ? 300 : 600} className="how-it-works__feature">
              <span className="how-it-works__feature-index">2</span>
              <h2 className="how-it-works__feature-heading">
                {formatMessage({ id: 'global.how_it_works_list.corgi_replaces_many_plugins.title1' })}
                <span className="blue">{formatMessage({ id: 'global.how_it_works_list.corgi_replaces_many_plugins.title2' })}</span>
              </h2>
              <p className="how-it-works__feature-description">
                {formatMessage({ id: 'global.how_it_works_list.corgi_replaces_many_plugins.description1' })}{' '}
                <b>{formatMessage({ id: 'global.how_it_works_list.corgi_replaces_many_plugins.description2' })}</b>{' '}
                {formatMessage({ id: 'global.how_it_works_list.corgi_replaces_many_plugins.description3' })}
              </p>
            </FadeInOnScroll>
            <FadeInOnScroll delay={isMobile ? 300 : 900} className="how-it-works__feature">
              <span className="how-it-works__feature-index">3</span>
              <h2 className="how-it-works__feature-heading">
                {formatMessage({ id: 'global.how_it_works_list.corgi_is_self_service.title1' })}{' '}
                <span className="how-it-works__feature-heading--blue">
                  {formatMessage({ id: 'global.how_it_works_list.corgi_is_self_service.title2' })}
                </span>
              </h2>
              <p className="how-it-works__feature-description">
                {formatMessage({ id: 'global.how_it_works_list.corgi_is_self_service.description1' })}{' '}
                <b>{formatMessage({ id: 'global.how_it_works_list.corgi_is_self_service.descirption2' })}</b>{' '}
                {formatMessage({ id: 'global.how_it_works_list.corgi_is_self_service.descirption3' })}
              </p>
            </FadeInOnScroll>
          </div>
          <FadeInOnScroll delay={isMobile ? 300 : 900} className="how-it-works__do-you-know">
            <div className="how-it-works__do-you-know-content">
              <SVG type={SVG_TYPE[`${translation.toLocaleUpperCase()}_DO_YOU_KNOW`]} />
              <h2 className="how-it-works__do-you-know-title">
                {formatMessage({ id: 'global.corgi_provides' })}{' '}
                <span className="how-it-works__do-you-know-title--yellow">{formatMessage({ id: 'global.dedicated_customer_service' })}</span>{' '}
                {formatMessage({ id: 'global.on_which_you_can_count' })}
              </h2>
              <p className="how-it-works__do-you-know-text">{formatMessage({ id: 'global.unlike_other_plugins' })}</p>
              <SVG type={SVG_TYPE.CORGI_HEADPHONES} className="how-it-works__corgi-headphones" />
              <SVG type={SVG_TYPE.QUESTION_MARK} className="how-it-works__question-mark" />
            </div>
          </FadeInOnScroll>
          <SVG type={SVG_TYPE.PAWS} className="how-it-works__paws" />
        </div>
      </section>

      <section className="section__wrapper section__wrapper-margin section--hero how-it-works__tricks-section">
        <div className="section__wrapper--gray"> </div>
        <div className="section__wrapper--blue section__wrapper--blue-large"> </div>
        <div className="how-it-works__gray-to-white">
          <div className="section homepage__section-content how-it-works__tricks">
            <h2 className="homepage__subtitle homepage__subtitle--white homepage__subtitle--white homepage__subtitle--one-tool homepage__subtitle--36 ">
              {formatMessage({ id: 'global.what_tricks_corgi_can_do' })}
            </h2>

            <div className="how-it-works__trick">
              <FadeInOnScroll delay={300} className="how-it-works__trick-text">
                <h2 className="how-it-works__trick-title">
                  {formatMessage({ id: 'global.what_tricks_corgi_can_do_options.regular_and_safe_backup.title' })}
                </h2>
                <p>
                  {formatMessage({ id: 'global.what_tricks_corgi_can_do_options.regular_and_safe_backup.description1' })}{' '}
                  <b>{formatMessage({ id: 'global.what_tricks_corgi_can_do_options.regular_and_safe_backup.description2' })}</b>
                  <br />
                  <br />
                  <b /> {formatMessage({ id: 'global.what_tricks_corgi_can_do_options.regular_and_safe_backup.description3' })}{' '}
                  <b>{formatMessage({ id: 'global.what_tricks_corgi_can_do_options.regular_and_safe_backup.description4' })}</b>{' '}
                  {formatMessage({ id: 'global.what_tricks_corgi_can_do_options.regular_and_safe_backup.description5' })}{' '}
                  <b>{formatMessage({ id: 'global.what_tricks_corgi_can_do_options.regular_and_safe_backup.description6' })}</b>
                </p>
              </FadeInOnScroll>
              <SlideOnScroll direction="right" delay={isMobile ? 300 : 900} className="how-it-works__trick-image how-it-works__trick-image--right">
                <SVG type={SVG_TYPE.BACKUP_CLOUD} className="how-it-works__trick-image--backup" />
              </SlideOnScroll>
            </div>

            <div className="how-it-works__trick">
              <SlideOnScroll direction="left" delay={isMobile ? 300 : 900} className="how-it-works__trick-image how-it-works__trick-image--left">
                <SVG type={SVG_TYPE.COPYRIGHT_SECOURITY} className="how-it-works__trick-image--security" />
              </SlideOnScroll>
              <FadeInOnScroll delay={300} className="how-it-works__trick-text">
                <h2 className="how-it-works__trick-title">
                  {formatMessage({ id: 'global.what_tricks_corgi_can_do_options.copyright_protection.title' })}
                </h2>
                <p>
                  {formatMessage({ id: 'global.what_tricks_corgi_can_do_options.copyright_protection.descirption1' })}
                  <br />
                  <br />
                  {formatMessage({ id: 'global.what_tricks_corgi_can_do_options.copyright_protection.description2' })}
                  <br />
                  <br />
                  {formatMessage({ id: 'global.what_tricks_corgi_can_do_options.copyright_protection.description3' })}{' '}
                  <b>{formatMessage({ id: 'global.what_tricks_corgi_can_do_options.copyright_protection.descirption4' })}</b>{' '}
                  {formatMessage({ id: 'global.what_tricks_corgi_can_do_options.copyright_protection.descirption5' })}{' '}
                </p>
              </FadeInOnScroll>
            </div>

            <div className="how-it-works__trick">
              <FadeInOnScroll delay={300} className="how-it-works__trick-text">
                <h2 className="how-it-works__trick-title">
                  {formatMessage({ id: 'global.what_tricks_corgi_can_do_options.automatic_updates.title' })}
                </h2>
                <p>
                  {formatMessage({ id: 'global.what_tricks_corgi_can_do_options.automatic_updates.descirption1' })}{' '}
                  <b>{formatMessage({ id: 'global.what_tricks_corgi_can_do_options.automatic_updates.descirption2' })}</b>{' '}
                  {formatMessage({ id: 'global.what_tricks_corgi_can_do_options.automatic_updates.descirption3' })} <br />
                  <br />
                  {formatMessage({ id: 'global.what_tricks_corgi_can_do_options.automatic_updates.descirption4' })}{' '}
                  <b>{formatMessage({ id: 'global.what_tricks_corgi_can_do_options.automatic_updates.descirption5' })}</b>{' '}
                  {formatMessage({ id: 'global.what_tricks_corgi_can_do_options.automatic_updates.descirption6' })}{' '}
                  <b>{formatMessage({ id: 'global.what_tricks_corgi_can_do_options.automatic_updates.descirption7' })}</b> <br />
                  <br />
                  {formatMessage({ id: 'global.what_tricks_corgi_can_do_options.automatic_updates.descirption8' })}
                </p>
              </FadeInOnScroll>
              <SlideOnScroll
                direction="right"
                delay={isMobile ? 300 : 900}
                className="how-it-works__trick-image how-it-works__trick-image--right how-it-works__trick-image--update">
                <SVG type={SVG_TYPE.AUTOMATIC_UPDATE} className="how-it-works__trick-image--updates" />
              </SlideOnScroll>
            </div>

            <div className="how-it-works__trick">
              <SlideOnScroll
                direction="left"
                delay={isMobile ? 300 : 900}
                className="how-it-works__trick-image how-it-works__trick-image--left how-it-works__trick-image--monitoring">
                <SVG className="svg--monitoring" type={SVG_TYPE.CONSTANT_MONITORING} />
                <div className="how-it-works__ups">
                  <div className="how-it-works__ups-content">
                    <span className="how-it-works__ups--bold"> {formatMessage({ id: 'global.ups' })}</span>{' '}
                    {formatMessage({ id: 'global.your_page_has_crashed' })}
                    <SVG type={SVG_TYPE.BIG_LOUPE} />
                  </div>
                </div>
              </SlideOnScroll>
              <FadeInOnScroll delay={300} className="how-it-works__trick-text">
                <h2 className="how-it-works__trick-title">
                  {formatMessage({ id: 'global.what_tricks_corgi_can_do_options.constant_monitoring.title' })}
                </h2>
                <p>
                  {formatMessage({ id: 'global.what_tricks_corgi_can_do_options.constant_monitoring.descirption1' })}{' '}
                  <b>{formatMessage({ id: 'global.what_tricks_corgi_can_do_options.constant_monitoring.descirption2' })}</b>{' '}
                  {formatMessage({ id: 'global.what_tricks_corgi_can_do_options.constant_monitoring.descirption3' })} <br />
                  <br />
                  {formatMessage({ id: 'global.what_tricks_corgi_can_do_options.constant_monitoring.descirption4' })}{' '}
                  <b>{formatMessage({ id: 'global.what_tricks_corgi_can_do_options.constant_monitoring.descirption5' })}</b>{' '}
                  {formatMessage({ id: 'global.what_tricks_corgi_can_do_options.constant_monitoring.descirption6' })}{' '}
                  <b>{formatMessage({ id: 'global.what_tricks_corgi_can_do_options.constant_monitoring.descirption7' })}</b> <br />
                  <br />
                  {formatMessage({ id: 'global.what_tricks_corgi_can_do_options.constant_monitoring.descirption8' })}{' '}
                  <b>{formatMessage({ id: 'global.what_tricks_corgi_can_do_options.constant_monitoring.descirption9' })}</b>{' '}
                  {formatMessage({ id: 'global.what_tricks_corgi_can_do_options.constant_monitoring.descirption10' })}
                </p>
                <Button
                  classNames="btn--black how-it-works__trick-button"
                  label={<FormattedMessage id="global.see_all_functions" />}
                  onClick={() => setIsModalOpen(true)}
                />
              </FadeInOnScroll>
            </div>
          </div>
        </div>
      </section>

      <section className="section__wrapper">
        <div className="section homepage__section-content how-it-works__why-i-need-corgi">
          <h2 className="homepage__subtitle homepage__subtitle--color-262626 homepage__subtitle--color-262626 homepage__subtitle--36 homepage__subtitle--one-tool">
            {formatMessage({ id: 'global.why_i_need_corgi' })}
          </h2>
          <div className="how-it-works__why-i-need-corgi-list">
            <FadeInOnScroll delay={isMobile ? 300 : 600} className="how-it-works__hosting">
              <SVG type={SVG_TYPE.HOSTING} className="how-it-works__hosting" />
            </FadeInOnScroll>
            <FadeInOnScroll delay={isMobile ? 300 : 300} className="how-it-works__why-i-need-corgi-list-item">
              <SVG type={SVG_TYPE.BULB} />
              <h2 className="how-it-works__feature-heading">
                {formatMessage({ id: 'global.why_i_need_corgi_options.backups.title1' })}{' '}
                <span className="how-it-works__feature-heading--blue">
                  {formatMessage({ id: 'global.why_i_need_corgi_options.backups.title2' })},
                </span>{' '}
                {formatMessage({ id: 'global.why_i_need_corgi_options.backups.title3' })}{' '}
                <span className="how-it-works__feature-heading--blue">{formatMessage({ id: 'global.why_i_need_corgi_options.backups.title4' })}</span>
              </h2>
              <p className="how-it-works__feature-description">
                {formatMessage({ id: 'global.why_i_need_corgi_options.backups.description1' })}{' '}
                <b>{formatMessage({ id: 'global.why_i_need_corgi_options.backups.descirption2' })}.</b>{' '}
                {formatMessage({ id: 'global.why_i_need_corgi_options.backups.descirption3' })}
              </p>
            </FadeInOnScroll>

            <FadeInOnScroll delay={isMobile ? 300 : 600} className="how-it-works__why-i-need-corgi-list-item">
              <SVG type={SVG_TYPE.BULB} />
              <h2 className="how-it-works__feature-heading">
                {formatMessage({ id: 'global.why_i_need_corgi_options.updates.title1' })}{' '}
                <span className="how-it-works__feature-heading--blue">{formatMessage({ id: 'global.why_i_need_corgi_options.updates.title2' })}</span>{' '}
                {formatMessage({ id: 'global.why_i_need_corgi_options.updates.title3' })}
              </h2>
              <p className="how-it-works__feature-description">{formatMessage({ id: 'global.why_i_need_corgi_options.updates.description' })}</p>
            </FadeInOnScroll>

            <FadeInOnScroll delay={isMobile ? 300 : 900} className="how-it-works__why-i-need-corgi-list-item-wrapper">
              <FadeInOnScroll delay={isMobile ? 300 : 600} className="how-it-works__why-i-need-corgi-bg">
                <SVG type={SVG_TYPE.PACKETS_PREMIUM_NO_BG} />
              </FadeInOnScroll>
              <div className="how-it-works__why-i-need-corgi-list-item">
                <SVG type={SVG_TYPE.BULB} />
                <h2 className="how-it-works__feature-heading">
                  {formatMessage({ id: 'global.why_i_need_corgi_options.monitoring.title1' })}{' '}
                  <span className="how-it-works__feature-heading--blue">
                    {formatMessage({ id: 'global.why_i_need_corgi_options.monitoring.title2' })}
                  </span>
                </h2>
                <p className="how-it-works__feature-description">
                  {formatMessage({ id: 'global.why_i_need_corgi_options.monitoring.descirption1' })}{' '}
                  <b>{formatMessage({ id: 'global.why_i_need_corgi_options.monitoring.descirption2' })}</b>{' '}
                  {formatMessage({ id: 'global.why_i_need_corgi_options.monitoring.descirption3' })}
                  <br />
                  <br />
                  {formatMessage({ id: 'global.why_i_need_corgi_options.monitoring.descirption4' })}
                </p>
              </div>
            </FadeInOnScroll>

            <FadeInOnScroll delay={isMobile ? 300 : 1200} className="how-it-works__why-i-need-corgi-list-item">
              <SVG type={SVG_TYPE.BULB} />
              <h2 className="how-it-works__feature-heading">
                {formatMessage({ id: 'global.why_i_need_corgi_options.stores_data.title1' })}{' '}
                <span className="how-it-works__feature-heading--blue">
                  {formatMessage({ id: 'global.why_i_need_corgi_options.stores_data.title2' })}
                </span>
              </h2>
              <p className="how-it-works__feature-description">{formatMessage({ id: 'global.why_i_need_corgi_options.stores_data.descirption' })}</p>
            </FadeInOnScroll>
          </div>
        </div>
      </section>

      <section className="section__wrapper">
        <div className="homepage__gray" />
        <div className="section section--center homepage__calendry-wrapper">
          <FadeInOnScroll delay={isMobile ? 300 : 600} className="homepage__calendry">
            <InlineWidget url="https://calendly.com/tomaszorganek/corgi_demo" />
          </FadeInOnScroll>
          <FadeInOnScroll className="homepage__demo">
            <img src={TomekWebp} alt="Tomasz Organek" />
            <h2 className="homepage__subtitle homepage__subtitle--black homepage__subtitle--partner homepage__subtitle--big homepage__subtitle--mg-b-24">
              {formatMessage({ id: 'global.make_a_demo' })}
            </h2>
            <p className="homepage__demo-description">
              {formatMessage(isMobile() ? { id: 'global.make_a_demo_description_mobile' } : { id: 'global.make_a_demo_description' })}
            </p>
          </FadeInOnScroll>
        </div>
      </section>
    </div>
  );
};

export default HowItWorks;
