import { Connector } from 'Modules/WebsiteModule';
import { Website } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Website.GET_PAGE_SPEED_INIT,
});

const success = (data) => ({
  type: Website.GET_PAGE_SPEED_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Website.GET_PAGE_SPEED_FAILURE,
});

const getPageSpeed = (uuid, date) => async (dispatch) => {
  dispatch(init());

  try {
    const data = await Connector.getPageSpeed(uuid, date);
    dispatch(success(data.data));
  } catch (err) {
    dispatch(failure());
  }
};

export default getPageSpeed;
