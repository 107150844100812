import { PROVIDERS } from "./provider"


export const ftpImages = {
  [PROVIDERS.HOME_PL]: {
    pl: [
      require('Assets/Image/ftp-instructions/home_pl/pl/step1.png'),
      require('Assets/Image/ftp-instructions/home_pl/pl/step2.png'),
      require('Assets/Image/ftp-instructions/home_pl/pl/step3.png'),
      require('Assets/Image/ftp-instructions/home_pl/pl/step4.png'),
      require('Assets/Image/ftp-instructions/home_pl/pl/step5.png'),
      require('Assets/Image/ftp-instructions/home_pl/pl/step6.png'),
      require('Assets/Image/ftp-instructions/home_pl/pl/step7.png'),
      require('Assets/Image/ftp-instructions/home_pl/pl/step8.png'),
    ],
  },
  [PROVIDERS.CYBERFOLKS_PL]: {
    pl: [
      require('Assets/Image/ftp-instructions/cyberfolks_pl/pl/step1.png'),
      require('Assets/Image/ftp-instructions/cyberfolks_pl/pl/step2.png'),
      require('Assets/Image/ftp-instructions/cyberfolks_pl/pl/step3.png'),
      require('Assets/Image/ftp-instructions/cyberfolks_pl/pl/step4.png'),
      require('Assets/Image/ftp-instructions/cyberfolks_pl/pl/step5.png'),
      require('Assets/Image/ftp-instructions/cyberfolks_pl/pl/step6.png'),
      require('Assets/Image/ftp-instructions/cyberfolks_pl/pl/step7.png'),
    ]
  },
  [PROVIDERS.LH_PL]: {
    pl: [
      require('Assets/Image/ftp-instructions/lh_pl/pl/step1.png'),
      require('Assets/Image/ftp-instructions/lh_pl/pl/step2.png'),
      require('Assets/Image/ftp-instructions/lh_pl/pl/step3.png'),
      require('Assets/Image/ftp-instructions/lh_pl/pl/step4.png'),
    ]
  },
  [PROVIDERS.NAZWA_PL]: {
    pl: [
      require('Assets/Image/ftp-instructions/nazwa_pl/pl/step1.png'),
      require('Assets/Image/ftp-instructions/nazwa_pl/pl/step2.png'),
      require('Assets/Image/ftp-instructions/nazwa_pl/pl/step3.png'),
      require('Assets/Image/ftp-instructions/nazwa_pl/pl/step4.png'),
      require('Assets/Image/ftp-instructions/nazwa_pl/pl/step5.png'),
    ]
  },
  [PROVIDERS.SMARTHOST_PL]: {
    pl: [
      require('Assets/Image/ftp-instructions/smarthost_pl/pl/step1.png'),
      require('Assets/Image/ftp-instructions/smarthost_pl/pl/step2.png'),
      require('Assets/Image/ftp-instructions/smarthost_pl/pl/step3.png'),
      require('Assets/Image/ftp-instructions/smarthost_pl/pl/step4.png'),
    ],
    en: [
      require('Assets/Image/ftp-instructions/smarthost_pl/en/step1.png'),
      require('Assets/Image/ftp-instructions/smarthost_pl/en/step2.png'),
      require('Assets/Image/ftp-instructions/smarthost_pl/en/step3.png'),
      require('Assets/Image/ftp-instructions/smarthost_pl/en/step4.png'),
    ],
    de: [
      require('Assets/Image/ftp-instructions/smarthost_pl/de/step1.png'),
      require('Assets/Image/ftp-instructions/smarthost_pl/de/step2.png'),
      require('Assets/Image/ftp-instructions/smarthost_pl/de/step3.png'),
      require('Assets/Image/ftp-instructions/smarthost_pl/de/step4.png'),
    ],
  },
  [PROVIDERS.UTI_PL]: {
    pl: [
      require('Assets/Image/ftp-instructions/uti_pl/pl/step1.png'),
      require('Assets/Image/ftp-instructions/uti_pl/pl/step2.png'),
      require('Assets/Image/ftp-instructions/uti_pl/pl/step3.png'),
      require('Assets/Image/ftp-instructions/uti_pl/pl/step4.png'),
    ]
  },
  [PROVIDERS.ZENBOX_PL]: {
    pl: [
      require('Assets/Image/ftp-instructions/zenbox_pl/pl/step1.png'),
      require('Assets/Image/ftp-instructions/zenbox_pl/pl/step2.png'),
      require('Assets/Image/ftp-instructions/zenbox_pl/pl/step3.png'),
      require('Assets/Image/ftp-instructions/zenbox_pl/pl/step4.png'),
      require('Assets/Image/ftp-instructions/zenbox_pl/pl/step5.png'),
      require('Assets/Image/ftp-instructions/zenbox_pl/pl/step5.png'),
    ]
  },
  [PROVIDERS.AZ_PL]: {
    pl: [
      require('Assets/Image/ftp-instructions/az_pl/pl/step1.png'),
      require('Assets/Image/ftp-instructions/az_pl/pl/step2.png'),
      require('Assets/Image/ftp-instructions/az_pl/pl/step3.png'),
      require('Assets/Image/ftp-instructions/az_pl/pl/step4.png'),
    ],
  },
  [PROVIDERS.OVHCLOUD_PL]: {
    pl: [
      require('Assets/Image/ftp-instructions/ovhcloud_pl/pl/step1.png'),
      require('Assets/Image/ftp-instructions/ovhcloud_pl/pl/step2.png'),
      require('Assets/Image/ftp-instructions/ovhcloud_pl/pl/step3.png'),
      require('Assets/Image/ftp-instructions/ovhcloud_pl/pl/step4.png'),
      require('Assets/Image/ftp-instructions/ovhcloud_pl/pl/step5.png'),
      require('Assets/Image/ftp-instructions/ovhcloud_pl/pl/step6.png'),
    ],
  },
  [PROVIDERS.SEOHOST_PL]: {
    pl: [
      require('Assets/Image/ftp-instructions/seohost_pl/pl/step1.png'),
      require('Assets/Image/ftp-instructions/seohost_pl/pl/step2.png'),
      require('Assets/Image/ftp-instructions/seohost_pl/pl/step3.png'),
      require('Assets/Image/ftp-instructions/seohost_pl/pl/step4.png'),
      require('Assets/Image/ftp-instructions/seohost_pl/pl/step5.png'),
    ],
  },
  [PROVIDERS.KEI_PL]: {
    pl: [
      require('Assets/Image/ftp-instructions/kei_pl/pl/step1.png'),
      require('Assets/Image/ftp-instructions/kei_pl/pl/step2.png'),
      require('Assets/Image/ftp-instructions/kei_pl/pl/step3.png'),
      require('Assets/Image/ftp-instructions/kei_pl/pl/step4.png'),
    ],
  },
  [PROVIDERS.DHOSTING_PL]: { 
    pl: [
      require('Assets/Image/ftp-instructions/dhosting_pl/pl/step1.png'),
      require('Assets/Image/ftp-instructions/dhosting_pl/pl/step2.png'),
      require('Assets/Image/ftp-instructions/dhosting_pl/pl/step3.png'),
      require('Assets/Image/ftp-instructions/dhosting_pl/pl/step4.png'),
    ],
  }
}