/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import Button from 'Shared/components/button/Button';
import { FormattedMessage } from 'react-intl';
import CorgiProFull from '../../Assets/Image/corgi_pro_full.svg';
import CorgiGlasses from '../../Assets/Image/corgi_glasses.svg';
import CorgiGlassesWoof from '../../Assets/Image/corgi_glasses_woof.svg';
import { MainType } from './Container';
import { Modal } from 'Shared/components/';
import ContactForm from '../../Components/ContactForm/Container';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'Shared/enums';

const CorgiProBono = ({ isMobile, intl: { formatMessage } }: MainType) => {
  const [isOpened, setIsOpened] = useState(null);
  const onClose = () => setIsOpened(null);
  const history = useHistory();
  return (
    <>
      <MetaTags>
        <title>{formatMessage({ id: 'global.metatag.pro_bono_title' })}</title>
        <meta name="description" content={formatMessage({ id: 'global.metatag.pro_bono_description' })} />
      </MetaTags>
      <Modal opened={isOpened} onClose={onClose}>
        <ContactForm />
      </Modal>
      <div className="page-corgi">
        <div className="container number__wrapper proBonoWrapper">
          <h1>Corgi pro bono</h1>
          <div className="number number_1 proBono">
            <div className="number__content proBono__content">
              <h3>
                <FormattedMessage id="global.corgi_pro_bono.corgi_helps" />
              </h3>
              <span>
                <FormattedMessage id="global.corgi_pro_bono.descritpion_2" />
              </span>
              <p>
                <FormattedMessage id="global.corgi_pro_bono.why_is_so_important" />
              </p>
            </div>
            <img className="proBono__image1" src={CorgiProFull} alt="" />
          </div>
          <div className="number number_2 proBono">
            {!isMobile && <img className="proBono__image2" src={CorgiGlasses} alt="" />}
            <div className="number__content proBono__content">
              <span>
                <FormattedMessage id="global.corgi_pro_bono.description_3" />
              </span>
              <p>
                <FormattedMessage id="global.corgi_pro_bono.ears_up" />
              </p>
            </div>
            {isMobile && <img className="proBono__image2" src={CorgiGlasses} alt="" />}
          </div>
          <div className="number number_3 proBono">
            <div className="number__content proBono__content">
              <span>
                <FormattedMessage id="global.corgi_pro_bono.description_4" />
              </span>
              <Button
                type="submit"
                onClick={() => history.push(ROUTES.CONTACT)}
                label={<FormattedMessage id="global.write_us" />}
                classNames="btn--transparent-white-border proBono__btn"
                iconAlt="icon product"
              />
            </div>
            <img className="proBono__image3" src={CorgiGlassesWoof} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default CorgiProBono;
