import React from 'react';
import SingleInstructionHeader from './SingleInstructionHeader';
import FtpInstruction from './FtpInstruction';

interface FtpSingleInstructionProps {
  provider: string;
  setProvider: (provider: string) => void;
}
const FtpSingleInstruction = ({ provider, setProvider }) => {
  return (
    <main className="ftp-single-instruction">
      <SingleInstructionHeader provider={provider} setProvider={setProvider} />
      <FtpInstruction provider={provider} />
    </main>
  );
};

export default FtpSingleInstruction;
