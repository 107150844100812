import MetaTags from 'react-meta-tags';
import TomekWebp from 'Assets/Image/tomasz_organek.png';
import FadeInOnScroll from 'Components/FadeInOnScroll/FadeInOnScroll';
import React from 'react';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
import { isMobile } from 'Shared/utils';
import { MainType } from './Container';
import { InlineWidget } from 'react-calendly';
import { ContactSocials } from './components';
import ContactForm from 'Components/ContactForm';

const Contact = ({ intl: { formatMessage } }: MainType) => {
  return (
    <>
      <MetaTags>
        <title>{formatMessage({ id: 'global.metatag.contact_title' })}</title>
        <meta name="description" content={formatMessage({ id: 'global.metatag.contact_description' })} />
      </MetaTags>
      <div className="home-page contact">
        <section className="section__wrapper section--gray section--gray-small-margin">
          <div className="section--background section--background--top250 contact__background">
            <SVG type={SVG_TYPE.HERO_SECTION_BG} />
          </div>
          <div className="section banner mainHeading__content heroSection homepage__section-content">
            <FadeInOnScroll>
              <h1 className="section--header mainHeading">
                <p className="paragraph">{formatMessage({ id: 'global.do_you_have_questions' })} </p>
              </h1>
              <p className="section--under-header">{formatMessage({ id: 'global.contact_us' })}</p>
              <ul className="contact__list">
                <li className="contact__list-item">
                  <SVG type={SVG_TYPE.PHONE_ICON} />
                  <a href="tel: +48 787 951 101">+48 787 951 101</a>
                </li>
                <li className="contact__list-item">
                  <SVG type={SVG_TYPE.MAIL_ICON_3} />
                  <a href="mailto: hello@corgi.pro">hello@corgi.pro</a>
                </li>
                <li className="contact__list-item">
                  <SVG type={SVG_TYPE.LOCATION_ICON} />
                  <p>
                    Podole 60
                    <br /> 30-394 Kraków
                  </p>
                </li>
              </ul>
              {isMobile() ? null : <ContactSocials formatMessage={formatMessage} />}
            </FadeInOnScroll>
            <FadeInOnScroll delay={isMobile ? 300 : 900} className="contact__form-wrapper" id="form">
              <ContactForm />
              <SVG type={SVG_TYPE.SIT_DOWN} className="contact__corgi" />
              {isMobile() ? <ContactSocials formatMessage={formatMessage} /> : null}
            </FadeInOnScroll>
          </div>
        </section>

      <section className="section__wrapper contact__demo">
        <div className="section section--center homepage__calendry-wrapper">
          <FadeInOnScroll delay={isMobile ? 300 : 600} className="homepage__calendry">
            <InlineWidget url="https://calendly.com/tomaszorganek/corgi_demo" />
          </FadeInOnScroll>
          <FadeInOnScroll className="homepage__demo">
            <img src={TomekWebp} alt="Tomasz Organek" />
            <h2 className="homepage__subtitle homepage__subtitle--black homepage__subtitle--partner homepage__subtitle--big homepage__subtitle--mg-b-24">
              {formatMessage({ id: 'global.make_a_consultation' })}
            </h2>
            <p className="homepage__demo-description">
              {formatMessage(isMobile() ? { id: 'global.make_a_demo_description_mobile' } : { id: 'global.make_a_demo_description' })}
            </p>
          </FadeInOnScroll>
        </div>
      </section>
    </div>
    </>
  );
};

export default Contact;
