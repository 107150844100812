import React from 'react';
import { getftpInstructionsByProvider } from '../utils';
import { injectIntl } from 'react-intl';

interface FtpInstructionProps {
  provider: string;
}

const FtpInstruction = ({ intl, provider }) => {
  const lang = intl?.locale || 'pl';
  const instructions = getftpInstructionsByProvider(lang)[provider];
  if (!instructions) {
    return <div>Brak instrukcji dla wybranego providera.</div>;
  }

  return (
    <div className="ftp-instructions-list">
      {instructions.map((step, index) => {
        return (
          <div className="ftp-instructions-list__item" key={index}>
            <div className="ftp-instructions-list__item--left">
              <div className="ftp-instructions-list__item-number">{index + 1}</div>
              <div className="ftp-instructions-list__item-heading">{step.title}</div>
              <div className="ftp-instructions-list__item-description">{step.description}</div>
            </div>
            <div className="ftp-instructions-list__item--right">
              <img src={step.image} alt="alt obrazka" />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default injectIntl(FtpInstruction);
