import { createSelector } from 'reselect';
import { RootState } from 'Store/Reducers/RootReducer';

// const getUserStore = (state: RootState) => state.userStore;

const getAddWebsiteStore = (state: RootState) => state.addWebsiteStore;
const getAddWebsiteFormData = createSelector(getAddWebsiteStore, ({ formData: { data } }) => data);
const getMaxStep = createSelector(getAddWebsiteStore, ({ formData: { maxStep } }) => maxStep);
const getCurrentStep = createSelector(getAddWebsiteStore, ({ formData: { currentStep } }) => currentStep);
const getPublicKey = createSelector(getAddWebsiteStore, ({ publicKey }) => publicKey);

export { getAddWebsiteFormData, getMaxStep, getCurrentStep, getPublicKey };
