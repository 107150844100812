import React from 'react';
import { SVG } from 'Shared/components';
import { SVG_TYPE, ROUTES } from 'Shared/enums';
import { isMobile } from 'Shared/utils';
import { history } from 'Store/Reducers/RootReducer';
import { PROVIDERS } from '../utils';

const FtpInstructionsMain = () => {
  const { HOME_PL, NAZWA_PL, CYBERFOLKS_PL, LH_PL, UTI_PL, AZ_PL, ZENBOX_PL, OVHCLOUD_PL, SEOHOST_PL, KEI_PL, SMARTHOST_PL, DHOSTING_PL } = PROVIDERS;
  const hostingProviders = isMobile()
    ? [HOME_PL, NAZWA_PL, CYBERFOLKS_PL, LH_PL, UTI_PL, AZ_PL, ZENBOX_PL, OVHCLOUD_PL, SEOHOST_PL, KEI_PL, SMARTHOST_PL, DHOSTING_PL]
    : [HOME_PL, ZENBOX_PL, NAZWA_PL, OVHCLOUD_PL, CYBERFOLKS_PL, SEOHOST_PL, LH_PL, KEI_PL, UTI_PL, SMARTHOST_PL, AZ_PL, DHOSTING_PL];

  const handleClickProvider = (provider: string) => {
    history.push(`${ROUTES.FTP_INSTRUCTIONS}?provider=${provider}`);
  };
  
  return (
    <>
      <main className="ftp-instructions">
        <div className="ftp-instructions__section ftp-instructions__section--top">
          <div className="ftp-instructions__content">
            <div className="ftp-instructions__background">
              <SVG type={SVG_TYPE.FTP_INSTRUCTIONS_BG} />
            </div>
            <div className="ftp-instructions__box">
              <div className="ftp-instructions__left">
                <h1 className="ftp-instructions__heading">Poradnik</h1>
                <h2 className="ftp-instructions__description">Jak utworzyć konto FTP?</h2>
                <p className="ftp-instructions__paragraph">
                  Każdy hosting działa trochę inaczej, dlatego przygotowaliśmy instrukcje aż dla 12 najpopularniejszych dostawców w Polsce. Kliknij w
                  wybraną platformę z listy poniżej, aby zobaczyć szczegółowy poradnik.
                </p>
                <p className="ftp-instructions__paragraph">
                  Nie widzisz swojego dostawcy? Masz problem z wygenerowaniem danych? <a href="#">Skontaktuj się z nami</a> Chętnie pomożemy!😊
                </p>

                <div className="ftp-instructions__hosting-providers">
                  {hostingProviders.map((provider, index) => (
                    <nav key={index} className="ftp-instructions__hosting-provider" onClick={() => handleClickProvider(provider)}>
                      {provider} <SVG type={SVG_TYPE.DIAGONAL_ARROW} />
                    </nav>
                  ))}
                </div>
              </div>
              <div className="ftp-instructions__right">
                <SVG type={SVG_TYPE.CORGI_SERVERS} />
              </div>
            </div>
          </div>
        </div>
        <div className="ftp-instructions__section ">
          <div className="ftp-instructions__content">
            <div className="ftp-instructions__background ftp-instructions__background--explanation">
              <SVG type={SVG_TYPE.FTP_INSTRIUCTIONS_BLUE_BG} />
            </div>
            <div className="ftp-instructions__explanation-box">
              <div className="ftp-instructions__explanation-cloud">
                <h3 className="ftp-instructions__explanation-heading">FTP (File Transfer Protocol) – co to jest? 💡</h3>
                <p className="ftp-instructions__explanation-paragraph">
                  FTP to sposób na <span>wysyłanie i pobieranie plików</span> między Twoim komputerem a serwerem, na którym działa strona. To trochę
                  przypomina zarządzanie plikami na dysku zewnętrznym - z tą różnicą, że wszystkie operacje odbywają się przez internet.
                </p>
                <p className="ftp-instructions__explanation-paragraph">
                  Po połączeniu z serwerem możesz przeglądać, dodawać, modyfikować i usuwać pliki dokładnie tak, jak w zwykłym eksploratorze plików.
                </p>
                <ul>
                  <p className="ftp-instructions__explanation-paragraph">
                    Żeby <span>połączyć się z serwerem FTP</span>, potrzebujesz:
                  </p>
                  <li className="ftp-instructions__explanation-list-item">🌐 Adresu serwera (np. ftp.twojastrona.pl)</li>
                  <li className="ftp-instructions__explanation-list-item">🔐 Loginu oraz hasła</li>
                  <li className="ftp-instructions__explanation-list-item">💻 Programu do połączenia (np. FileZilla, Cyberduck)</li>
                </ul>
              </div>
              <SVG
                className="ftp-instructions__explanation-box--svg"
                type={isMobile() ? SVG_TYPE.FTP_CORGI_EXPLANATION_MOBILE : SVG_TYPE.FTP_CORGI_EXPLANATION}
              />
            </div>
          </div>
          <div className="ftp-instructions__explanation-bottom" />
        </div>
      </main>
    </>
  );
};

export default FtpInstructionsMain;
