export const PROVIDERS = {
  HOME_PL: 'home.pl',
  NAZWA_PL: 'nazwa.pl',
  CYBERFOLKS_PL: 'cyberFolks.pl',
  LH_PL: 'LH.pl',
  UTI_PL: 'uti.pl',
  AZ_PL: 'az.pl',
  ZENBOX_PL: 'zenbox.pl',
  OVHCLOUD_PL: 'OVHcloud.pl',
  SEOHOST_PL: 'seohost.pl',
  KEI_PL: 'kei.pl',
  SMARTHOST_PL: 'smarthost.pl',
  DHOSTING_PL: 'dhosting.pl',
};