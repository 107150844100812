import React from 'react';
import { SVG, Button } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
import { isMobile } from 'Shared/utils';

interface SingleInstructionHeaderProps {
  provider: string;
  setProvider: (provider: string) => void;
}
const SingleInstructionHeader = ({ provider, setProvider }) => {
  const handleGoBack = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('provider');
    const newUrl = window.location.pathname;
    window.history.replaceState({}, '', newUrl);
    setProvider('');
  };

  const providerVideoLinks: Record<string, string | undefined> = {
    'home.pl': 'https://www.youtube.com/watch?si=N6SFsI-k_oe1yVB0&v=-2L7TAQxNIQ&feature=youtu.be',
    'cyberFolks.pl': 'https://www.youtube.com/watch?si=8uPUkOr_NXwjCiSx&v=s2GyKMeD9W4&feature=youtu.be',
    'seohost.pl': 'https://www.youtube.com/watch?v=nDpEopP6tE4&ab_channel=SeoHost',
  };

  const videoLink = providerVideoLinks[provider];

  const handleClickVideo = (videoLink: string) => {
    if (videoLink) {
      window.open(videoLink, '_blank');
    }
  };
  return (
    <div className="ftp-single-instruction__header">
      <div className="ftp-single-instruction__header-background">
        <SVG type={SVG_TYPE.FTP_INSTRUCTIONS_BG} />
      </div>
      <div className="ftp-single-instruction__header-content">
        <div className="ftp-single-instruction__header--left">
          <div className="ftp-single-instruction__header-go-back" onClick={() => handleGoBack()}>
            <div className="ftp-single-instruction__header-go-back-icon">
              <SVG type={SVG_TYPE.ARROW_LEFT} />
            </div>
            Wróć
          </div>
          <h1 className="ftp-instructions__heading">Poradnik</h1>
          <h2 className="ftp-instructions__description">Jak utworzyć konto FTP?</h2>
          <div className="ftp-single-instruction__provider">👉 {provider}</div>
          {!isMobile() && (
            <p className="ftp-instructions__paragraph">Aby utworzyć nowe konto FTP w home.pl, postępuj zgodnie z poniższymi krokami:</p>
          )}
        </div>
        {videoLink ? (
          <div className="ftp-single-instruction__movie ftp-single-instruction__header--right">
            <div className="ftp-single-instruction__movie-description">
              <div className="ftp-single-instruction__movie-heading">Wolisz obejrzeć filmik instruktażowy? </div>
              <div className="ftp-single-instruction__movie-description">
                Sprawdź nagranie przygotowane przez home.pl, w którym krok po kroku pokazują, jak utworzyć nowe konto FTP. Kliknij poniżej i zobacz
                wideo! 👇
              </div>
              <Button
                iconImgSvg={SVG_TYPE.MEDIA_PLAY}
                classNames="blue-with-white-border"
                iconPlacement="right"
                label="Obejrzyj film instruktażowy"
                onClick={() => handleClickVideo(videoLink)}
              />
              <div className="ftp-single-instruction__movie-icon">
                <SVG type={SVG_TYPE.CORGI_PREMIUM_FLY} />
              </div>
            </div>
          </div>
        ) : (
          <>
            {!isMobile() && (
              <div className="ftp-single-instruction__half-head">
                <SVG type={SVG_TYPE.CORGI_HEAD_COOL} />
              </div>
            )}
          </>
        )}

        {isMobile() && <p className="ftp-instructions__paragraph">Aby utworzyć nowe konto FTP w home.pl, postępuj zgodnie z poniższymi krokami:</p>}
      </div>
    </div>
  );
};

export default SingleInstructionHeader;
