import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Selectors, Actions } from 'Modules/ViewManagementModule';
import { Selectors as PaymentSelectors, Actions as PaymentActions } from 'Modules/PaymentModule';
import * as H from 'history';
import { ROUTES, ROUTES_ADMIN_PANEL, ROUTES_USER_PANEL } from 'Shared/enums';
import { Footer, AuthRoute, BrowserLanguage, Locale, Loader } from './Shared/components';
import HomePage from 'Screens/HomePage/Container';
import CorgiWebinar from 'Screens/CorgiWebinar/Container';
import AboutCorgi from 'Screens/AboutCorgi/Main';
import WhyCorgi from 'Screens/WhyCorgi/Container';
import Faq from 'Screens/Faq/Container';
import Page404 from 'Screens/Page404';
import FtpInstructions from 'Screens/FtpInstructions';
import Soon from 'Screens/Soon/Container';
import PreorderSignUp from 'Screens/PreorderSignUp/Container';
import NewsletterSignUp from 'Screens/NewsletterSignUp/Container';
import PartnerSignUp from 'Screens/PartnerSignUp/Container';
import MessageSend from 'Screens/MessageSend/Container';
const Dashboard = lazy(() => import('Screens/Dashboard/Container'));
import AboutUs from 'Screens/AboutUs/Container';
import Contact from 'Screens/Contact/Container';
import Header from './Shared/components/header/Container';
import CheckYourWebsite from 'Screens/CheckYourWebsite/Main';
import ChecWebsiteByUrl from 'Screens/CheckWebsiteByUrl/Main';
import RemindPassword from 'Screens/RemindPassword/Container';
import Report from 'Screens/Report/Report.container';
import { RootState } from 'Store/Reducers/RootReducer';
import { success as loginSuccess } from 'Store/Actions/Auth/login';
import { setLocalStorage } from 'Shared/utils';
import ScrollToTop from './Shared/components/scrollTop';
import CorgiProBono from 'Screens/ProBono/Container';
import HowItWorks from 'Screens/HowItWorks/Container';
import Page500 from 'Screens/Page500';
import { bindActionCreators } from 'redux';
import AddWebsite from 'Screens/AddWebsite/Container';
import SvgTestPage from 'Screens/SvgTestPage/Main';
import WebsiteChecker from 'Screens/WebsiteChecker/Container';
import LoginPage from 'Screens/LoginPage/Container';
import RegisterPage from 'Screens/RegisterPage/Container';
import ResetPasswordPage from 'Screens/ResetPasswordPage/Container';
import Guide from 'Screens/Guide/Main';
import Pricing from 'Screens/Pricing/Container';
import moment from 'moment';
import 'moment/locale/pl';
import 'moment/locale/de';
moment.locale(Locale());

declare global {
  interface Window {
    translations: any;
  }
}

type P = {
  history: any;
  location?: H.Location;
};
class App extends React.Component<ConnectedP & DispatchedP & P, any> {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const {
      location: { search, pathname },
      history: { replace },
      actions: { getStripePrice, setAppTranslation },
      stripePrices,
    } = this.props;
    setAppTranslation(Locale() || BrowserLanguage(setAppTranslation));
    window.scrollTo(0, 0);
    if (search.split('=')[0] === '?refCode') {
      setLocalStorage('refCode', search.split('=')[1]);
      replace(pathname);
    }
    if (search.split('=')[0] === '?lang') {
      setAppTranslation(search.split('=')[1]);
      sessionStorage.setItem('language', search.split('=')[1]);
      replace(pathname);
      window.location.reload();
    } else {
      setAppTranslation(Locale() || sessionStorage.getItem('language') || BrowserLanguage(setAppTranslation));
    }
    !stripePrices && (await getStripePrice());
  }

  public render() {
    const {
      location: { pathname },
      location,
      isModalOpened,
      history,
      actions: { openModal, closeModal },
      translation,
    } = this.props;

    const {
      LOGIN,
      DASHBOARD,
      ABOUT_CORGI,
      HOME_PAGE,
      ABOUT_US,
      CONTACT,
      SOON,
      WHY_CORGI,
      PREORDER_SIGN_UP,
      PARTNER_SIGN_UP,
      NEWSLETTER_SIGN_UP,
      FAQ,
      PAGE_404,
      MESSAGE_SEND,
      PACKAGES,
      MY_WEBSITES,
      MY_DATA,
      PARTNER,
      PARTNER_CLIENT,
      CHANGE_PASSWORD,
      CLIENTS,
      ALL_WEBISTE,
      PAYMENTS_LISTS,
      PARTNERS_COPY,
      REMIND_PASSWORD,
      RESET_PASSWORD,
      UPDATES,
      BACKUPS,
      NOTIFICATIONS,
      PARTNERS_WEBSITES,
      FILES_CHANGES,
      CORGI_WEBINAR,
      CORGI_PRO_BONO,
      PAGE_500,
      REPORT,
      WEBSITE_CHECKER,
      CHECK_WEBSITE,
      ADD_WEBSITE,
      GUIDE,
      MY_ACCOUNT,
      CHECK_YOUR_WEBSITE,
      HOME_PAGE_COPY,
      CHECKER_LISTS,
      MY_WEBSITES_COPY,
      SVG_TEST,
      RESET_PASSWORD_PAGE,
      REGISTER_PAGE,
      SINGLE_WEBSITE,
      HOW_IT_WORKS,
      PRICING,
      FTP_INSTRUCTIONS,
    } = ROUTES;
    const { PREORDER, MAILING } = ROUTES_ADMIN_PANEL;
    const { CARSMILE } = ROUTES_USER_PANEL;
    const isReportView = pathname === REPORT;
    const areAccessoriesShown =
      pathname !== FTP_INSTRUCTIONS &&
      pathname !== DASHBOARD &&
      pathname !== PREORDER &&
      pathname !== MAILING &&
      pathname !== CARSMILE &&
      pathname !== SOON &&
      pathname !== PREORDER_SIGN_UP &&
      pathname !== MESSAGE_SEND &&
      pathname !== NEWSLETTER_SIGN_UP &&
      pathname !== PARTNER_SIGN_UP &&
      pathname !== PAGE_404 &&
      pathname !== MY_WEBSITES &&
      pathname !== MY_WEBSITES_COPY &&
      pathname !== PAGE_404 &&
      pathname !== MY_DATA &&
      pathname !== PARTNER &&
      pathname !== PARTNER_CLIENT &&
      pathname !== PARTNERS_COPY &&
      pathname !== CHANGE_PASSWORD &&
      pathname !== CLIENTS &&
      pathname !== ALL_WEBISTE &&
      pathname !== NOTIFICATIONS &&
      pathname !== BACKUPS &&
      pathname !== UPDATES &&
      pathname !== SINGLE_WEBSITE &&
      pathname !== PARTNERS_WEBSITES &&
      pathname !== FILES_CHANGES &&
      pathname !== FILES_CHANGES &&
      pathname !== CHECK_YOUR_WEBSITE &&
      pathname !== CHECKER_LISTS &&
      pathname !== CHECK_WEBSITE &&
      pathname !== PAYMENTS_LISTS &&
      pathname !== MY_ACCOUNT;
    return (
      <>
        <div className={`main-wrapper ${isModalOpened ? 'blurred' : ''} ${`main-wrapper--${pathname.replace('/', '')}`}`}>
          {translation ? (
            <ScrollToTop>
              {/* <IntlProvider
                messages={flattenMessages(window?.translations?.[translation])}
                locale={translation}
                key={translation}
                defaultLocale={translation}> */}
              <div className={`page__inner ${`page__inner--${pathname.replace('/', '')}`}`}>
                {areAccessoriesShown && !isReportView && <Header location={location} />}
                <Suspense fallback={<Loader />}>
                  <Switch>
                    <Route exact path={CORGI_WEBINAR} component={CorgiWebinar} />
                    <Route exact path={HOME_PAGE} component={HomePage} />
                    <Route exact path={HOME_PAGE_COPY} component={HomePage} />
                    <Route exact path={HOW_IT_WORKS} component={HowItWorks} />
                    <Route exact path={PRICING} component={Pricing} />
                    <Route exact path={HOW_IT_WORKS} component={HowItWorks} />
                    <Route exact path={ABOUT_CORGI} component={AboutCorgi} />
                    <Route exact path={ABOUT_US} component={AboutUs} />
                    <Route exact path={CONTACT} component={Contact} />
                    <Route exact path={WHY_CORGI} component={WhyCorgi} />
                    <Route exact path={LOGIN} component={LoginPage} />
                    <Route exact path={PREORDER_SIGN_UP} component={PreorderSignUp} />
                    <Route exact path={NEWSLETTER_SIGN_UP} component={NewsletterSignUp} />
                    <Route exact path={PARTNER_SIGN_UP} component={PartnerSignUp} />
                    <Route exact path={MESSAGE_SEND} component={MessageSend} />
                    <Route exact path={FAQ} component={Faq} />
                    <Route exact path={PACKAGES} component={Pricing} />
                    <Route exact path={REMIND_PASSWORD} component={RemindPassword} />
                    <Route exact path={RESET_PASSWORD} component={ResetPasswordPage} />
                    <Route exact path={RESET_PASSWORD_PAGE} component={ResetPasswordPage} />
                    <Route exact path={CORGI_PRO_BONO} component={CorgiProBono} />
                    <Route exact path={PAGE_500} component={Page500} />
                    <Route exact path={REPORT} component={Report} />
                    <Route exact path={WEBSITE_CHECKER} component={WebsiteChecker} />
                    <Route exact path={CHECK_YOUR_WEBSITE} component={CheckYourWebsite} />
                    <Route exact path={CHECK_WEBSITE} component={ChecWebsiteByUrl} />
                    <Route exact path={REGISTER_PAGE} component={RegisterPage} />
                    <Route path={FTP_INSTRUCTIONS} component={FtpInstructions} />
                    <Route path={ADD_WEBSITE} component={AddWebsite} />
                    <AuthRoute path={SOON} component={Soon} />
                    <AuthRoute path={DASHBOARD} component={Dashboard} />
                    <Route exact path={GUIDE} component={Guide} />
                    <Route exact path={SVG_TEST} component={SvgTestPage} />
                    <Route render={() => <Page404 location={location} />} />
                  </Switch>
                </Suspense>
                {areAccessoriesShown && !isReportView && <Footer openModal={openModal} closeModal={closeModal} history={history} />}
              </div>
              {/* </IntlProvider> */}
            </ScrollToTop>
          ) : null}
        </div>
      </>
    );
  }
}

type ConnectedP = {
  isModalOpened: boolean;
  stripePrices: object;
  translation: string;
  history?: any;
};
type DispatchedP = {
  actions: {
    loginUserFromStorage: (token: string) => void;
    openModal: () => void;
    closeModal: () => void;
    setAppTranslation: (lang: string) => void;
    getStripePrice: any;
  };
};

// const mapDispatchToProps: DispatchedP = {
//   openModal: () => Actions.openModal(),
//   closeModal: () => Actions.closeModal(),
//   loginUserFromStorage: (token: string) => loginSuccess({ token }),
//   setAppTranslation: (lang) => Actions.setAppTranslation(lang),
//   getStripePrice: () => PaymentActions.getStripePrice(),
// };

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      openModal: () => Actions.openModal(),
      closeModal: () => Actions.closeModal(),
      loginUserFromStorage: (token: string) => loginSuccess({ token }),
      setAppTranslation: (lang) => Actions.setAppTranslation(lang),
      getStripePrice: () => PaymentActions.getStripePrice(),
    },
    dispatch,
  ),
});

function mapStateToProps(state: RootState): ConnectedP {
  return {
    isModalOpened: Selectors.getIsModalOpened(state),
    translation: Selectors.getTranslation(state),
    stripePrices: PaymentSelectors.getStripePrice(state),
  };
}

const Routes = connect(mapStateToProps, mapDispatchToProps)(App);

export default Routes;
